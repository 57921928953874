import {
  faChevronDown,
  faChevronUp,
  faEdit,
  faReply,
  faCheckSquare,
  faCommentAltLines,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons"
import { faCheckSquare as faCheckSquareActive } from "@fortawesome/pro-solid-svg-icons"
import { faFlag } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react"
import { useAnimatedCallback } from "../../hooks/use-animated-callback"
import { useModal } from "../../hooks/use-modal"
import { useStyle } from "../../hooks/use-style"
import { useUser, getCachedSecret } from "../../hooks/use-user"
import { prettyDate, exactDate } from "../../services/date"
import { rpc } from "../../services/node"
import { prettyAmount } from "../../services/number"
import { darkTheme, lightTheme } from "../../services/theme"
import { Comment, CommentTarget, LikeType } from "../../types"
import DirectMessageDialog from "../dialogs/direct-message-dialog"
import OptionsButton from "../layout/button/options"
import Description from "../layout/description"
import Panel from "../layout/panel"
import { showToast } from "../status-notification"
import Flags from "../widgets/flags"
import HtmlTooltip from "../widgets/html-tooltip"
import CommentOptions from "./comment-options"
import Comments from "./comments"
import CommentInput from "./input"
import CommentLabel from "./label"

type Props = {
  target: CommentTarget
  comment: Comment
  onLike: (commentId: string, amount: number) => void
  onDislike: (commentId: string, amount: number) => void
  publishComment: (amount: number, text: string, commentId: string) => void
  hideComment: (commentId: string) => void
  isDonation: boolean
}

const CommentItem: FunctionComponent<Props> = ({
  target,
  comment,
  onLike,
  onDislike,
  publishComment,
  hideComment,
  isDonation,
}) => {
  const [showReplies, setShowReplies] = useState(false)
  const [edit, setEdit] = useState(false)
  const { setContent } = useModal()

  const { user, commentLikes, accountState } = useUser()
  const isLiked = useMemo(() => commentLikes.includes(comment.id), [
    commentLikes,
    comment.id,
  ])

  //  const reply = useCallback(() => {
  //    /*onReply(comment.dht_key)*/
  //  }, [comment.dht_key, onReply])
  //  const cancel = useCallback(() => onReply(null), [onReply])

  const likeButton = useRef<HTMLButtonElement>(null)
  const likeComment = useAnimatedCallback(
    likeButton,
    "heartBeat",
    () => onLike(comment.dht_key, 1),
    [comment.dht_key, onLike]
  )

  const upvote = useCallback(() => {
    onLike(comment.id, user ? user.like_value : 0.01)
  }, [user, comment.id, onLike])

  const loadReplies = useCallback(() => {
    setShowReplies(showReplies => !showReplies)
  }, [showReplies])

  const css = useStyle(theme => ({
    infoColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  const onEdit = useCallback(() => {
    console.log("onEdit")
    setEdit(true)
  }, [setEdit])

  const onEditSubmit = useCallback(
    (amount: number, text: string, commentId: string) => {
      publishComment(amount, text, commentId)
      setEdit(false)
    },
    [setEdit]
  )

  const onDelete = useCallback(() => {
    publishComment(1, "", comment.id)
    setEdit(false)
  }, [setEdit, comment.id])

  const sendMessage = useCallback(() => {
    if (user) {
      const msg = {
        from: user.id,
        to: comment.author.id,
        text: "",
        text_outbox: "",
      }
      setContent(
        <DirectMessageDialog initMessage={msg} close={() => setContent(null)} />
      )
    }
  }, [user, target])

  const markAsRead = useCallback(() => {
    if (user) {
      rpc("mark_feedback_read", comment.id, user, getCachedSecret(), function (
        res
      ) {
        let statusMessage
        if (res.status == "ok") {
          statusMessage = "Donation marked as read"
          hideComment(comment.id)
        } else statusMessage = "Failed mark donation as read"

        showToast(faInfoCircle, "Smartlike network", statusMessage)
      })
    }
  }, [user, target])

  return (
    <article>
      <div id={comment.id} className="hook"></div>

      <div className="main">
        <div className="layout">
          <Panel url="#">
            {{
              header: (
                <>
                  {edit == false && (
                    <div className="table">
                      <header className="table-cell">
                        <CommentLabel channel={comment.author} />
                      </header>
                      {!isDonation && (
                        <div className="table-cell">
                          <div className="published">
                            {comment.edited}&nbsp;
                            {prettyDate(comment.published_at, true)}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ),
              content: (
                <div className="description">
                  {edit ? (
                    <div style={{ width: "100%", marginBottom: "10px" }}>
                      <CommentInput
                        onSubmit={onEditSubmit}
                        borderless
                        autofocus={true}
                        placeholder="Write a comment..."
                        initialValue={comment.text}
                        commentId={comment.id}
                      />
                    </div>
                  ) : (
                    <Description linkify>{comment.text}</Description>
                  )}
                </div>
              ),
              footer: (
                <>
                  <div>
                    {comment.comments_count > 0 && (
                      <p
                        className="replies"
                        onClick={() => loadReplies(comment.id)}
                      >
                        {comment.comments_count}
                        &nbsp;
                        {comment.comments_count === 1 ? "reply" : "replies"}
                      </p>
                    )}
                  </div>
                  <div>
                    {comment.flags && comment.flags.length > 0 && (
                      <div style={{ marginTop: "10px" }}>
                        <Flags
                          contentType={LikeType.Commentator}
                          target={target.id}
                          id={comment.id}
                          flags={comment.flags}
                          flag_stats={comment.flag_stats}
                        />
                      </div>
                    )}
                  </div>

                  {/*comment.amount > 0 && (
                      <p>${(comment.amount / 100).toFixed(2)}</p>
                    )*/}
                </>
              ),
              upperToolbar: (
                <>
                  {isDonation && (
                    <>
                      <div className="table-cell">
                        <div className="published">
                          {exactDate(comment.published_at)}
                        </div>
                      </div>
                      <div className="table-cell">
                        <div className="amount">
                          {user ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: user.displayed_currency_symbol,
                              }}
                            />
                          ) : (
                            <>&#36;</>
                          )}{" "}
                          {prettyAmount(comment.amount)}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="options">
                    <CommentOptions
                      comment={comment}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      hideComment={hideComment}
                    />{" "}
                  </div>
                </>
              ),
              lowerToolbar: (
                <>
                  {!isDonation ? (
                    <>
                      {accountState > 0 ? (
                        <div style={{ marginTop: "5px", display: "flex" }}>
                          <div className="vote" onClick={loadReplies}>
                            <FontAwesomeIcon icon={faReply} />
                          </div>

                          <div className="table">
                            <div className="table-row">
                              <div
                                className="vote"
                                onClick={() => onDislike(comment.id, 1)}
                              >
                                <FontAwesomeIcon icon={faChevronDown} />
                              </div>
                              <div
                                style={{ visibility: "visible" }}
                                className={
                                  comment.score >= 0
                                    ? "score-positive"
                                    : "score-negative"
                                }
                              >
                                {comment.score}
                              </div>
                              <div className="vote" onClick={() => upvote()}>
                                <FontAwesomeIcon icon={faChevronUp} />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            visibility: "visible",
                            paddingRight: "10px",
                          }}
                          className={
                            comment.score >= 0
                              ? "score-positive"
                              : "score-negative"
                          }
                        >
                          {comment.score}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {target.owned && (
                        <div
                          style={{
                            display: "inline-flex",
                            visibility: "visible",
                            marginTop: "3px",
                          }}
                        >
                          <HtmlTooltip title="Private message" placement="top">
                            <div
                              className="thumbsUpContainer"
                              onClick={() => sendMessage()}
                            >
                              <FontAwesomeIcon
                                className=""
                                icon={faCommentAltLines}
                                size="lg"
                              />
                            </div>
                          </HtmlTooltip>
                          {comment.read ? (
                            <HtmlTooltip title="Marked as read" placement="top">
                              <div className="thumbsUpContainerActive">
                                <FontAwesomeIcon
                                  className=""
                                  icon={faCheckSquareActive}
                                  size="lg"
                                />
                              </div>
                            </HtmlTooltip>
                          ) : (
                            <HtmlTooltip title="Mark as read" placement="top">
                              <div
                                className="thumbsUpContainer"
                                onClick={() => markAsRead()}
                              >
                                <FontAwesomeIcon
                                  className=""
                                  icon={faCheckSquare}
                                  size="lg"
                                />
                              </div>
                            </HtmlTooltip>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </>
              ),
            }}
          </Panel>
        </div>

        {showReplies && (
          <div className="comments">
            <Comments
              target={{
                kind: LikeType.Comment,
                id: comment?.id,
                url: target.url,
                count: comment?.comments_count,
              }}
              parentComment={comment.id}
            />
          </div>
        )}
      </div>

      <style jsx>{`
        .thumbsUpContainer {
          color: rgb(129, 129, 129);
          padding-right: 10px;
          margin: auto;
        }
        .thumbsUpContainer:hover {
          cursor: pointer;
          color: blue;
        }
        .thumbsUpContainerActive {
          color: rgb(129, 129, 129);
          padding-right: 10px;
          margin: auto;
        }
        /*
        .thumbsUpContainerActive:hover {
          color: blue;
        }*/

        .amount {
          font-size: 12px;
          font-weight: 500;
          font-family: Roboto;
          min-width: 40px;
          text-align: right;
          /*color: rgb(129, 129, 129);*/
        }

        @media (hover: hover) and (pointer: fine) {
          .options {
            visibility: hidden;
          }
          article:hover .options {
            visibility: visible;
          }
        }

        article {
          display: flex;
          border-radius: 0.3em;
          position: relative;
          align-items: flex-start;
        }
        .hook {
          position: absolute;
          top: -80px;
        }
        .main {
          flex: 1;
        }
        .layout {
          display: flex;
          overflow-wrap: break-word;
          justify-content: space-between;
          min-width: 0;
          border-top: 1px solid ${css.borderColor};
          padding-top: 1em;
          transition: 200ms border-color;
        }
        h1 {
          font-size: 0.9em;
          margin: 0.15em 0 0.15em;
        }
        .description {
          font-size: 0.8em;
          margin-top: 5px;
        }
        p {
          display: flex;
          font-size: 0.8em;
          color: ${css.infoColor};
          transition: 200ms color;
          margin: 0;
        }
        p:not(:last-child)::before {
          /*content: "•";
            margin-left: -0.5em;*/
          margin-left: 1em;
          padding-right: 0.5em;
        }
        .comments:not(:empty) {
          margin-top: 1em;
          margin-left: 2em;
        }
        .head {
          display: inline-flex;
        }
        .published {
          font-size: 0.8em;
          color: ${css.infoColor};
          margin-left: 1em;
          font-family: "Roboto";
        }
        .published::before {
          /*content: "•";*/
          margin-left: 1em;
          padding-right: 1em;
        }
        .replies {
          font-size: 14px;
        }
        .replies:hover {
          cursor: pointer;
          text-decoration: underline;
        }
        .table {
          display: table;
        }
        .table-row {
          display: table-row;
        }
        .table-cell {
          display: table-cell;
          vertical-align: middle;
        }
        .vote {
          display: table-cell;
          vertical-align: middle;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 14px;
        }
        .vote:hover {
          cursor: pointer;
        }
        .score-positive {
          font-size: 12px;
          color: green;
          font-weight: 600;
        }
        .score-negative {
          font-size: 12px;
          color: red;
          font-weight: 600;
        }
      `}</style>
    </article>
  )
}

export default CommentItem
