import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import Widget from "../../components/layout/widget"
import { useComments } from "../../hooks/use-comments"
import { CommentTarget, Comment } from "../../types"
import CommentInput from "./input"
import CommentItem from "./comment-item"
import Notice from "../widgets/notice"
import { useScroll } from "../../hooks/use-scroll"
import { useIndexedDB } from "../../hooks/use-indexeddb"
import { useFilters } from "../../hooks/use-filters"
import { GET_comments } from "../../services/http"
import { convertComments } from "../../services/content"
import { useLazyLoading } from "../../hooks/use-lazy-loading"
import { useUser } from "../../hooks/use-user"
import { Link } from "@reach/router"

type Props = {
  target: CommentTarget
  parentComment: string
}

const Comments: FunctionComponent<Props> = ({ target, parentComment }) => {
  const {
    comments,
    setComments,
    upvoteComment,
    downvoteComment,
    publishComment,
    hideComment,
  } = useComments(target, [], true, parentComment)

  const { commentFilters, filters } = useFilters()
  const { muteComments } = useIndexedDB()
  const { accountState } = useUser()

  const fetchPage = useCallback(
    async (offset: number) => {
      //console.log("fetchPage")
      const response = await Promise.resolve(
        // TODO: prevent requests if no data
        GET_comments(target.id, {
          languages: filters.languages?.length
            ? filters.languages.join(",")
            : undefined,
          countries: filters.countries?.length
            ? filters.countries.join(",")
            : undefined,
          count: 20,
          offset: offset * 20,
          sort: commentFilters.sort,
          /*age: filters.age,*/
        })
      )
      if (response?.hits?.hits.length) {
        return convertComments(response, filters)
      } else return []
    },
    [commentFilters, filters]
  )

  const add = useCallback(
    (newItems: Comment[], offset: number) => {
      if (newItems) {
        //console.log(feedId + " " + offset + " " + newItems)
        if (offset == 0 && comments.length > 0) {
          console.log("clearing items")
          setComments(newItems)
        } else setComments(comments => comments.concat(newItems))
      }
      // TODO: trigger single rererender
    },
    [comments]
  )

  useEffect(() => {
    //console.log("reloading")
    resetLoader()
  }, [commentFilters])

  const { isLoading, isContentAvailable, resetLoader } =
    useLazyLoading<Comment>(comments, { fetchPage, add })

  const [count, setCount] = useState(target.count)
  const onComment = useCallback(
    (amount: number, text: string, commentId: string) => {
      setCount(count => count + 1)
      publishComment(amount, text, commentId)
    },
    [count, publishComment]
  )

  return (
    <>
      {accountState > 0 && parentComment == "" && (
        <div style={{ marginTop: "20px" }}>
          <Notice id="comment_notice" submit="Got it">
            <p>
              Since Smartlike accounts are anonymous and there's no centralized
              user registration, comments have to be protected from spam by
              attaching a 1 cent donation to content author.
            </p>
            <p>
              The good news is that when your comment is upvoted, you receive
              the money as upvotes are donations too. <a href="#">Read more.</a>
            </p>
          </Notice>
        </div>
      )}
      <Widget title="Comments" numComments={count}>
        <ul>
          {accountState > 0 ? (
            <li>
              <CommentInput
                onSubmit={onComment}
                borderless
                autofocus={true}
                placeholder="Write a comment..."
              />
            </li>
          ) : (
            <li>
              You need a Smartlike account to comment.{" "}
              <Link to={"/login"}>Create one</Link>?
            </li>
          )}

          {comments.map(comment => (
            <li key={comment.id}>
              <CommentItem
                target={target}
                comment={comment}
                onLike={upvoteComment}
                onDislike={downvoteComment}
                publishComment={publishComment}
                hideComment={hideComment}
                isDonation={false}
              />
            </li>
          ))}
        </ul>
      </Widget>
      <style jsx>{`
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
        li:not(:first-child) {
          margin-top: 1em;
        }
        li:not(:last-child) {
          margin-bottom: 1em;
        }
      `}</style>
    </>
  )
}

export default Comments
